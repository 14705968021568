<template>
  <v-container class="fill-height">
    <v-card flat class="justify-center pa-2">
      <v-form @submit.prevent="forgetPassword">
        <v-card class="mx-auto pa-6 pb-8" elevation="3" rounded="lg" :max-width="cardMaxWidth">
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="12" md="4" class="d-flex justify-center mb-4 mb-md-0" v-if="faviconImage || lightImage">
              <v-img
                :src="logoImage"
                alt="Protected"
                :max-height="imageMaxHeight"
                :max-width="imageMaxWidth"
                contain
              ></v-img>
            </v-col>
            <v-col cols="12" md="8">
              <div class="text-subtitle-2 text-medium-emphasis">Email</div>

              <v-text-field
                :density="textFieldDensity"
                placeholder="Email address"
                prepend-inner-icon="mdi-email-outline"
                v-model.trim="user.email"
                required
                solo
                outlined
                :tabindex="1"
              ></v-text-field>

              <v-btn
                class="my-2"
                color="primary"
                :size="buttonSize"
                rounded
                block
                type="submit"
                :tabindex="2"
              >
                Reset Password
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </v-card>
    <v-snackbar :color="snackColor" v-model="snackbar" :timeout="2000">{{ response }}</v-snackbar>

  </v-container>
</template>

<script>
import { auth } from "../../firebaseDb";
import { mapGetters } from "vuex";
import useLocalStorage from "../../composables/useLocalStorage";

export default {
  data() {
    return {
      user: {
        email: "",
      },
      response: "",
      snackColor: "black",
      snackbar: false,
      lightImage: null,
      faviconImage: null,
    };
  },
  created() {
    const faviconImageStorage = useLocalStorage(null, "faviconImage");
    const lightImageStorage = useLocalStorage(null, "lightImage");
    this.faviconImage = faviconImageStorage.get();
    this. lightImage = lightImageStorage.get();
  },
  computed: { 
    ...mapGetters(["darkTheme"]),
    imageMaxHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? '200px' : '100px';
    },
    imageMaxWidth() {
      return this.$vuetify.breakpoint.mdAndUp ? '200px' : '100px';
    },
    cardMaxWidth() {
      return this.$vuetify.breakpoint.mdAndUp ? '700px' : '100%';
    },
    textFieldDensity() {
      return this.$vuetify.breakpoint.mdAndUp ? 'compact' : 'comfortable';
    },
    buttonSize() {
      return this.$vuetify.breakpoint.mdAndUp ? 'large' : 'small';
    },
    logoImage() {
      return this.$vuetify.theme.dark ? this.lightImage : this.faviconImage;
    },
  },
  methods: {
    forgetPassword() {
      auth
        .sendPasswordResetEmail(this.user.email)
        .then(() => {
          // alert("Check your registered email to reset the password!");
          this.snackbar = true;
          this.snackColor = "warning";
          this.response = "Check your registered email to reset the password!";
          this.user = {
            email: "",
          };
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.response = error.message;
        });
    },
  },
};
</script>

<style scoped>
input:not(:placeholder-shown) {
  border-color: #42b983; /* Example: Change border color */
  background-color: #e0f7fa; /* Example: Change background color */
}
</style>

